import { withDependencies } from '@wix/thunderbolt-ioc'
import { AssetInfo, DomReadySymbol, HeadContentSymbol, IHeadContent } from '@wix/thunderbolt-symbols'
import { PageResourceFetcherSymbol } from './symbols'
import { IPageResourceFetcher } from './IPageResourceFetcher'

export type ILoadPageStyle = {
	load(pageId: string): Promise<void>
}

const getAssetInfo = (pageId: string, url: string): AssetInfo => ({
	id: `css_${pageId}`,
	url,
})

const loadStylesheet = ({ id, url }: AssetInfo): Promise<void> =>
	new Promise((resolve) => {
		const linkElement = document.createElement('link')
		linkElement.setAttribute('rel', 'stylesheet')
		linkElement.setAttribute('type', 'text/css')
		linkElement.onload = function() {
			resolve()
		}
		linkElement.setAttribute('href', url)
		linkElement.setAttribute('id', id)
		document.head.appendChild(linkElement)
	})

const addStylesheetAndWait = (asset: AssetInfo): Promise<void> => {
	if (!document.getElementById(asset.id)) {
		return loadStylesheet(asset)
	}
	return Promise.resolve()
}

export const ClientPageStyleLoader = withDependencies<ILoadPageStyle>(
	[PageResourceFetcherSymbol, DomReadySymbol],
	(pageResourceFetcher: IPageResourceFetcher, domReadyPromise: Promise<void>) => ({
		async load(pageId): Promise<void> {
			const url = pageResourceFetcher.getResourceUrl(pageId, 'css')
			const assetInfo: AssetInfo = getAssetInfo(pageId, url)
			await domReadyPromise
			await addStylesheetAndWait(assetInfo)
		},
	})
)

export const ServerPageStyleLoader = withDependencies<ILoadPageStyle>(
	[HeadContentSymbol, PageResourceFetcherSymbol],
	(headContent: IHeadContent, pageResourceFetcher: IPageResourceFetcher) => ({
		async load(pageId) {
			// TODO - handle/catch site-assets client error
			const css = await pageResourceFetcher.fetchResource(pageId, 'css', 'enable')
			headContent.setHead(`<style id="css_${pageId}">${css}</style>`)
		},
	})
)
