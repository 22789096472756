import { withDependencies, multi } from '@wix/thunderbolt-ioc'
import { PopHistoryStateHandler, UrlChangeHandlerForPage } from './symbols'
import {
	IAppWillMountHandler,
	CurrentRouteInfoSymbol,
	BrowserWindowSymbol,
	BrowserWindow,
	ViewerModelSym,
	ViewerModel,
	SamePageUrlChangeListenerSymbol,
	ISamePageUrlChangeListener,
} from '@wix/thunderbolt-symbols'
import { IPageProvider, PageProviderSymbol } from 'feature-pages'
import {
	IUrlHistoryPopStateHandler,
	IUrlHistoryManager,
	IUrlChangeHandler,
	ICurrentRouteInfo,
	IUrlHistoryState,
} from './types'

export const UrlChangeListener = withDependencies(
	[PageProviderSymbol, CurrentRouteInfoSymbol],
	(pageProvider: IPageProvider, currentRouteInfo: ICurrentRouteInfo): ISamePageUrlChangeListener => {
		return {
			onUrlChange: async (url) => {
				const routeInfo = currentRouteInfo.getCurrentRouteInfo()
				if (routeInfo) {
					currentRouteInfo.updateRouteInfoUrl(url)
					const { contextId, pageId } = routeInfo
					const page = await pageProvider(contextId, pageId)
					const pageHandlers = page.getAllImplementersOf<IUrlChangeHandler>(UrlChangeHandlerForPage)
					return Promise.all(pageHandlers.map((handler) => handler.onUrlChange(url)))
				}
			},
		}
	}
)

export const UrlHistoryManager = withDependencies(
	[BrowserWindowSymbol, ViewerModelSym, SamePageUrlChangeListenerSymbol, CurrentRouteInfoSymbol],
	(
		browserWindow: BrowserWindow,
		viewerModel: ViewerModel,
		samePageUrlChangeListener: ISamePageUrlChangeListener,
		currentRouteInfo: ICurrentRouteInfo
	): IUrlHistoryManager => ({
		pushUrlState: (parsedUrl: URL, skipHistory?: boolean) => {
			if (!browserWindow || !browserWindow.history) {
				return
			}
			const url = parsedUrl.toString()
			const currentUrl = new URL(browserWindow.location.href)
			parsedUrl.searchParams.sort()
			currentUrl.searchParams.sort()

			const historyState: IUrlHistoryState = { scrollY: browserWindow.scrollY }
			if (skipHistory) {
				browserWindow.history.replaceState(historyState, '', url)
			}

			if (currentUrl.toString() === parsedUrl.toString()) {
				return
			}

			if (!skipHistory) {
				browserWindow.history.replaceState(historyState, '', currentUrl.toString())
				browserWindow.history.pushState(null, '', url)
			}

			const pageIdBeforeHandlingPushState = currentRouteInfo.getPreviousRouterInfo()?.pageId
			const pageIdAfterHandlingPushState = currentRouteInfo.getCurrentRouteInfo()?.pageId
			// upon first navigation, there's no prev route info
			if (!pageIdBeforeHandlingPushState || pageIdBeforeHandlingPushState === pageIdAfterHandlingPushState) {
				samePageUrlChangeListener.onUrlChange(new URL(url))
			}
		},
		getHistoryState: () => {
			if (!browserWindow || !browserWindow.history) {
				return null
			}
			return browserWindow.history.state as IUrlHistoryState
		},

		updateHistoryState: (newHistory?: IUrlHistoryState, scrollRestoration?: ScrollRestoration) => {
			if (!browserWindow || !browserWindow.history) {
				return
			}
			if (scrollRestoration) {
				browserWindow.history.scrollRestoration = scrollRestoration
			}

			if (newHistory) {
				const currentUrl = new URL(browserWindow.location.href)
				currentUrl.searchParams.sort()

				browserWindow.history.replaceState(newHistory, '', currentUrl.toString())
			}

			return
		},

		getParsedUrl: () => {
			if (browserWindow) {
				return new URL(browserWindow.location.href)
			} else {
				return new URL(viewerModel.requestUrl)
			}
		},
	})
)

export const PopStateListener = withDependencies(
	[multi(PopHistoryStateHandler), BrowserWindowSymbol, SamePageUrlChangeListenerSymbol, CurrentRouteInfoSymbol],
	(
		popStateHandlers: Array<IUrlHistoryPopStateHandler>,
		browserWindow: BrowserWindow,
		samePageUrlChangeListener: ISamePageUrlChangeListener,
		currentRouteInfo: ICurrentRouteInfo
	): IAppWillMountHandler => ({
		appWillMount: () => {
			if (!browserWindow) {
				return
			}
			browserWindow.addEventListener('popstate', async () => {
				const href = browserWindow.location.href
				await Promise.all(popStateHandlers.map((handler) => handler.onPopState(new URL(href))))

				const pageIdBeforeHandlingPopState = currentRouteInfo.getPreviousRouterInfo()?.pageId
				const pageIdAfterHandlingPopState = currentRouteInfo.getCurrentRouteInfo()?.pageId
				// when the first url change is due to a navigation to a tpa section, and the back button is hit, there's no prev route info
				if (!pageIdBeforeHandlingPopState || pageIdBeforeHandlingPopState === pageIdAfterHandlingPopState) {
					samePageUrlChangeListener.onUrlChange(new URL(href))
				}
			})
		},
	})
)
