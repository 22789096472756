import { multi, withDependencies } from '@wix/thunderbolt-ioc'
import { CurrentRouteInfoSymbol, IAppDidMountHandler, IRenderer, LifeCycle } from '@wix/thunderbolt-symbols'
import { AppProps, BatchingStrategy, ClientRenderResponse } from '../types'
import App from '../components/App'
import React from 'react'
import ReactDOM from 'react-dom'
import { ICurrentRouteInfo } from 'feature-router'
import { BatchingStrategySymbol } from '../symbols'

type ReactClientRendererFactory = (
	appDidMountHandlers: Array<IAppDidMountHandler>,
	currentRouteInfo: ICurrentRouteInfo,
	batchingStrategy: BatchingStrategy
) => IRenderer<AppProps, Promise<ClientRenderResponse>>

const reactClientRenderer: ReactClientRendererFactory = (appDidMountHandlers, currentRouteInfo, batchingStrategy) => ({
	render: async (props: AppProps) => {
		const target = document.getElementById('site-container') as HTMLElement
		await new Promise((resolve) =>
			ReactDOM.hydrate(
				<React.StrictMode>
					<App {...props} batchingStrategy={batchingStrategy} />
				</React.StrictMode>,
				target,
				resolve
			)
		)

		appDidMountHandlers.map((appDidMountHandler) => appDidMountHandler.appDidMount(target))
		const route = currentRouteInfo.getCurrentRouteInfo()
		return {
			firstPageId: (route && route.pageId) || 'PROTECTED',
		}
	},
})

export const ReactClientRenderer = withDependencies(
	[multi(LifeCycle.AppDidMountHandler), CurrentRouteInfoSymbol, BatchingStrategySymbol],
	reactClientRenderer
)
